import React from 'react';

const Contact = () => {
  return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="content-box max-w-lg mx-auto">
        <h2 className="text-2xl font-bold mb-4">Kontakt</h2>
        <p>Kontakt oss for mer informasjon.</p>
      </div>
    </div>
  );
};

export default Contact;
