import React from 'react';

const About = () => {
  return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="content-box max-w-lg mx-auto">
        <h2 className="text-2xl font-bold mb-4">Om</h2>
        <p>Informasjon om gården vår og hva vi gjør.</p>
      </div>
    </div>
  );
};

export default About;
