import axios from 'axios';

const getWeatherData = async () => {
  try {
    const response = await axios.get('https://api.met.no/weatherapi/locationforecast/2.0/compact', {
      params: {
        lat: 61.881,
        lon: 7.726,
      },
      headers: {
        'User-Agent': 'YourAppName/1.0 (your-email@example.com)' // replace with your details
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching weather data:', error);
    return null;
  }
};

export default getWeatherData;
