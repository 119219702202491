import React from 'react';

const Images = () => {
  return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="content-box max-w-lg mx-auto">
        <h2 className="text-2xl font-bold mb-4">Bilder</h2>
        <p>Her er noen bilder fra gården vår.</p>
      </div>
    </div>
  );
};

export default Images;
