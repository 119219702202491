import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaImages, FaInfoCircle, FaEnvelope, FaSun, FaMoon, FaBars } from 'react-icons/fa';

const Navbar = ({ toggleDarkMode, isDarkMode }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="bg-green-700 text-white p-4 flex justify-between items-center fixed w-full top-0 left-0 z-50">
      <h1 className="text-xl font-bold">SkjaakBonden.no</h1>
      <ul className={`flex space-x-4 ${menuOpen ? '' : 'hidden'} md:flex`}>
        <li><Link to="/" className="flex items-center"><FaHome className="mr-1"/> <span className="hidden md:inline">Hovedside</span></Link></li>
        <li><Link to="/bilder" className="flex items-center"><FaImages className="mr-1"/> <span className="hidden md:inline">Bilder</span></Link></li>
        <li><Link to="/om" className="flex items-center"><FaInfoCircle className="mr-1"/> <span className="hidden md:inline">Om</span></Link></li>
        <li><Link to="/kontakt" className="flex items-center"><FaEnvelope className="mr-1"/> <span className="hidden md:inline">Kontakt</span></Link></li>
      </ul>
      <div className="flex items-center space-x-4">
        <button onClick={toggleDarkMode} className="focus:outline-none">
          {isDarkMode ? <FaSun /> : <FaMoon />}
        </button>
        <button onClick={handleToggleMenu} className="md:hidden focus:outline-none">
          <FaBars />
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
