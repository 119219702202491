import React, { useEffect, useState } from 'react';
import getWeatherData from '../weatherService';

const Footer = () => {
  const [weather, setWeather] = useState({
    temperature: null,
    windSpeed: null,
    condition: null
  });

  useEffect(() => {
    const fetchWeather = async () => {
      const data = await getWeatherData();
      if (data) {
        const details = data.properties.timeseries[0].data.instant.details;
        const nextHour = data.properties.timeseries[1];
        const temperature = details.air_temperature;
        const windSpeed = details.wind_speed;
        const condition = nextHour.data.next_1_hours.summary.symbol_code;
        setWeather({
          temperature,
          windSpeed,
          condition
        });
      }
    };
    fetchWeather();
  }, []);

  const getWeatherCondition = (condition) => {
    if (condition.includes('snow')) return 'Snoggvær';
    if (condition.includes('sun')) return 'Sol';
    if (condition.includes('rain')) return 'Regn';
    return 'Skyfritt';
  };

  return (
    <footer className="bg-green-700 text-white text-center p-4 fixed bottom-0 w-full">
      <p>Laget og publisert av <a href="https://mouum.net" target="_blank" rel="noopener noreferrer" className="underline">Mouum.NET</a></p>
      {weather.temperature !== null && (
        <p>Været akkuratt nå hos Skjåkvegen 604: {weather.temperature}°C</p>
      )}
      {weather.windSpeed !== null && (
        <p>Vind: {weather.windSpeed} m/s</p>
      )}
      {weather.condition !== null && (
        <p>Værforhold: {getWeatherCondition(weather.condition)}</p>
      )}
    </footer>
  );
};

export default Footer;
