import React from 'react';

const Home = () => {
  return (
    <div className="flex justify-center items-center min-h-screen pt-16">
      <div className="content-box max-w-lg mx-auto">
        <h2 className="text-2xl font-bold mb-4">Velkommen til SkjaakBonden.no</h2>
        <p>Her finner du informasjon om vår gård og våre produkter.</p>
      </div>
    </div>
  );
};

export default Home;
